import Swiper from "swiper";
import { Pagination, Navigation } from 'swiper/modules';
class Behaviours {

	constructor( module ) {

		
		// Initializes sliders for only .gbcn-featured-real-state module.
		
		Array.prototype.forEach.call(document.querySelectorAll('.gbcn-grid-real-state [data-gold-swipe] .swiper'), (e) => {
			
			var swiper = new Swiper(e, {

				modules: [Navigation, Pagination],
				pagination: {
					el: e.querySelector('.swiper-pagination'),
					type: 'fraction',
				},
				navigation: {
					nextEl: e.querySelector('.swiper-button-next'),
					prevEl: e.querySelector('.swiper-button-prev'),
				},
			});
		});

		// Video

		Array.prototype.forEach.call(document.querySelectorAll('[data-gold-video]'), (e) => {
			let video = e.querySelector('video');
			let btVideo = e.querySelector('.ico-play');

			btVideo.addEventListener('click', () => {
				video.click();
			})

			video.addEventListener('click', function () {
				if (video.paused) {
					video.play();
					btVideo.style.display = 'none';
				} else {
					video.pause();
					btVideo.style.display = 'block';
				}
			}, false);
		});

		// Timeline

		Array.prototype.forEach.call(document.querySelectorAll('[data-gold-timeline] .swiper'), (e) => {
			var swiper = new Swiper(e, {
				modules: [Navigation],
				slidesPerView: "auto",
				spaceBetween: 0,
				freeMode: true,
				navigation: {
					nextEl: '.swiper-button-next',
					prevEl: '.swiper-button-prev',
				},
			});
		});

		// Go to up Button

		const goToUpButton = document.querySelector('.tts-go-to-up-button');
		if(goToUpButton) {
				goToUpButton.addEventListener('click', () => {
					window.scrollTo({ top: 0, behavior: 'smooth'});
				}
			)
		}
	}
}

export default Behaviours;