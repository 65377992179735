class ShareModal {

    constructor( module, callback ) {

        this._callback = callback;

        this._ACTIONS = new Map(
            [
                ['close-modal', this._close ],
                ['share-facebook', this._shareFacebook ],
                ['share-x', this._shareX ],
                ['share-whatsapp', this._shareWhatsapp ],
                ['share-telegram', this._shareTelegram ],
                ['copy-to-clipboard', this._copyToClipboard ]
            ] 
        );

        this._DOM_MAP = new Map(
            [
                [ "module", module ],
                [ "shareUrl", module.querySelector(".tts-share-modal__url") ],
                [ "background", module.querySelector(".tts-share-modal__background") ],
                [ "shareButton", module.querySelector(".tts-share-modal__share" ) ]
            ]
        );
    
        this._show();
    }

    _show() {

        document.body.insertBefore(this._DOM_MAP.get("module"), null);

        document.documentElement.classList.add("--modal");
        this._DOM_MAP.get("module").classList.add("--show");
        this._DOM_MAP.get("module").classList.remove("--hide");

        this._UIClickHandler = this._UIClickHandler.bind(this);
        this._DOM_MAP.get("module").addEventListener("click", this._UIClickHandler );
    }

    _UIClickHandler( e ) {

        const actionName = e.target.getAttribute("data-gold-action");
        if( actionName ) {
            const actionBehaviour = this._ACTIONS.get( actionName ).bind(this);
            actionBehaviour();
        }
        else {
            const modalDimensions = this._DOM_MAP.get("module").getBoundingClientRect()
            if (
                e.clientX < modalDimensions.left ||
                e.clientX > modalDimensions.right ||
                e.clientY < modalDimensions.top ||
                e.clientY > modalDimensions.bottom
            ) {
                this._close();
            }
        }
    }

    _animationEndHandler(e) {

        this._DOM_MAP.get("background").removeEventListener('animationend', this._animationEndHandler );
        document.documentElement.classList.remove("--modal");
        this._callback();
    }

    /* ACTIONS */
    _close() {

        this._animationEndHandler = this._animationEndHandler.bind( this );
        this._DOM_MAP.get("module").removeEventListener("click", this._UIClickHandler );
        this._DOM_MAP.get("background").addEventListener('animationend', this._animationEndHandler );
        this._DOM_MAP.get("module").classList.remove("--show");
        this._DOM_MAP.get("module").classList.add("--hide");
    }

    _copyToClipboard() {

        const textToCopy = this._DOM_MAP.get( "shareUrl" ).textContent;
        navigator.clipboard.writeText( textToCopy );
        this._DOM_MAP.get( "shareButton" ).animate(
            [
              { transform: 'scale(1)' }, 
              { transform: 'scale(1.5)' },
              { transform: 'scale(1)' }  
            ],
            {
              duration: 300, 
              easing: 'ease-out',
              fill: "both"
            }
        )
    }

    /* SHARE ACTIONS */

    _shareFacebook() {
        // Obtiene la URL de la página actual
        var url = window.location.href;
        // Abre una nueva ventana para compartir en Facebook
        window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(url), '_blank');
    }

    _shareX() { 
        // Obtiene la URL de la página actual
        const url = encodeURIComponent(window.location.href);
        // Abre una nueva ventana para compartir en Twitter
        window.open('https://twitter.com/intent/tweet?url=' + url, '_blank');
    }

    _shareWhatsapp() {
        // Obtiene la URL de la página actual
        const url = encodeURIComponent(window.location.href);
        // Obtiene el texto de la cabecera del mensaje
        const text = document.querySelector('#telegram_text').value;
        // Abre la aplicación de WhatsApp con un mensaje predefinido
        window.location.href = 'whatsapp://send?text=' + url;
    }

    _shareTelegram() {
        // Obtiene la URL de la página actual
        const url = encodeURIComponent(window.location.href);
        // Obtiene el texto de la cabecera del mensaje
        const text = document.querySelector('#telegram_text').value;
        // Abre la aplicación de Telegram con un mensaje predefinido
        window.open( 'https://telegram.me/share/url?url=' + url + '&text=' + text, '_blank' );
    }
}

export default ShareModal;