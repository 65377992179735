class MainMenu {

	constructor() {

		this._mainContainer = document.querySelector( ".gbcn-header" );
		this._burgerButton =  document.querySelector(".gbcn-header .input-bt-mobile");
		this._burgerButtonHandler = this._burgerButtonHandler.bind( this );
		this._burgerButton.addEventListener( "change", this._burgerButtonHandler );
		
		this._verticalScrollTracker= new MainMenuScrollController( this._mainContainer );

		this.clean();
	}

	//  Reactivate the vertical scroll on the page and fold the menu (mobile)
	clean() {

		this._uncheckBurgerButton()
		this._setDocumentOverflowY();
	}

	_burgerButtonHandler(e) {

		this._startOrStopVerticalScrollTracker( e.currentTarget.checked )
		this._setDocumentOverflowY( e.currentTarget.checked? "hidden" : null );
		this._toogleClassMenuIsDisplayed();
	}

	_startOrStopVerticalScrollTracker( stop = true ) {

		stop? this._verticalScrollTracker.stop() : this._verticalScrollTracker.start();
	}

	_toogleClassMenuIsDisplayed() {

		document.documentElement.classList.toggle( "--main-menu-displayed" );
	}

	_uncheckBurgerButton() {

		this._burgerButton.checked = false;
	}

	_setDocumentOverflowY( overflow = null ) {

		document.documentElement.style.overflowY = overflow;
	}
}

export default MainMenu;
class MainMenuScrollController {

    constructor ( mainNav_dn, notifications_dn = null ) {

      this._listenerFn = undefined;
      this.initController( mainNav_dn, notifications_dn );
    }
  
    initController ( mainNav_dn, notifications_dn = null ) {

      this._header = mainNav_dn;
      this._notifications_dn = notifications_dn || this._header;

      this._headerHeight = this._header.offsetHeight + 88;
      this._distanceToTop = Math.max( window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop );
      this._callback = this._throttle( this._callback.bind(this), 200 );
  
      this.start();
  
      if (this._distanceToTop > 0) {
        this._notifications_dn.classList.add('scrolled');
      }
    }
  
    addListener (listenerFn) {

      this._listenerFn = listenerFn;
    }
  
    removeListener () {

      this._listenerFn = undefined;
    }
  
    start () {

      this.stop()
      window.addEventListener( 'scroll', this._callback );
    }
  
    stop () {

	  window.removeEventListener( 'scroll', this._callback );
    }
  
    _throttle ( callback, wait = 100 ) {

      let timer = null;
  
      return function (args) {

        if ( timer === null ) {

          timer = setTimeout( () => {
            callback.apply(this, args);
            timer = null;
          }, wait )
        }
      }
    }
  
    _callback () {

      const newDistanceToTop = Math.max( window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop );
  
      if ( newDistanceToTop === 0 ) {

        this._notifications_dn.classList.remove( 'scrolled--transition' );
        this._notifications_dn.classList.remove( 'scrolled', 'scrolled--past', 'scrolled--show' );
        if ( this._listenerFn ) this._listenerFn();
        return;
      }
  
      if ( newDistanceToTop > this._headerHeight ) {

        this._notifications_dn.classList.add( 'scrolled--past' );
        const self = this;
        setTimeout( function () { self._notifications_dn.classList.add('scrolled--transition') }, 99 );
      }
  
      if ( newDistanceToTop < this._distanceToTop) {

        this._notifications_dn.classList.add( 'scrolled--show' );

      } else {

        this._notifications_dn.classList.add( 'scrolled' );
        this._notifications_dn.classList.remove( 'scrolled--show' );
      }
  
      this._distanceToTop = newDistanceToTop;
      if ( this._listenerFn ) this._listenerFn();
    }
  }

