import gsap from "gsap";
import Swiper from "swiper";
class FeaturedBoxes {

    constructor( module ) {
        
        this._DOM_MAP = new Map();
        this._swiperInstance = undefined;

        this._DOM_MAP.set( "module", module );
        this._DOM_MAP.set( "swiper", module.querySelector( ".swiper" ) );
        this._DOM_MAP.set( "boxList", Array.from( module.querySelectorAll( ".swiper-slide" ) ) );

        this._init();
    }

    _init() {

        this._initSlider();
        this._initAnimation();
    }

    _initAnimation() {

        gsap.timeline(
        {
            scrollTrigger: {
                trigger: this._DOM_MAP.get("boxList")[0],
                start: 'top 100%',
                end: 'top 20%', 
                scrub: true,
            }
          }
        )
        .fromTo( this._DOM_MAP.get("boxList")[0], { y : "50px" }, { y : "0" } )
        .fromTo( this._DOM_MAP.get("boxList")[1], { y : "100px" }, { y : "0" } )
        .fromTo( this._DOM_MAP.get("boxList")[2], { y : "150px" }, { y : "0" } )
    }

    _initSlider() {

        this._swiperInstance = new Swiper( 
            this._DOM_MAP.get("swiper"), 
            {
                loop: false,
                slidesPerView: "auto",
                scrollbars:false,
                watchOverflow: true,  
            }
        );
    }

    destroy() {

        if( this._swiperInstance ) this._swiperInstance.destroy();
    }
}

export default FeaturedBoxes;