import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';

class BannerHeader {

    constructor( module ) {
        
        this._DOM_MAP = new Map();
        this._swiperInstance = undefined;

        this._DOM_MAP.set( "module", module );
        this._DOM_MAP.set( "logo", module.querySelector( ".logo" ) );

        this._init();
    }

    _init() {

        this._initAnimation();
    }

    _initAnimation() {

        gsap.registerPlugin(ScrollTrigger);

        this._animation = gsap.to( this._DOM_MAP.get( "logo"), {  y: -40, duration: 1 });
        this._animationScrollTrigger = ScrollTrigger.create(
            {
                trigger: this._DOM_MAP.get( "logo"),
                start: "top 90%", 
                end: "top 70%", 
                once: true, 
                animation: this._animation
            }
        );
        
        this._animation.eventCallback("onComplete", e => {

            const currentLogoY = gsap.getProperty( this._DOM_MAP.get( "logo"), "y") + "px"; 

            this._animationParallax = gsap.fromTo( this._DOM_MAP.get( "logo") , { y : currentLogoY }, { yPercent : -50 } )
            this._parallaxScrollTrigger = ScrollTrigger.create( 
                    {
                        trigger: this._DOM_MAP.get( "logo"),
                        start: "top 50%", 
                        end: "top 20%", 
                        scrub: true,
                        animation: this._animationParallax
                    }
                );
            }
        );
    }

    destroy() {

        if( this._animation ) {

            this._animation.eventCallback("onComplete", null);
            this._animation.kill();
            this._animation = null;
            this._animationScrollTrigger.kill();
            this._animationScrollTrigger = null;
        }

        if( this._parallaxScrollTrigger ) {

            this._animationParallax.kill();
            this._animationParallax = null;
            this._parallaxScrollTrigger.kill();
            this._parallaxScrollTrigger = null;
        }
    }
}

export default BannerHeader;