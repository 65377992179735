import { Loader } from "@googlemaps/js-api-loader";
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import Swiper from "swiper";
import { Pagination, Navigation } from 'swiper/modules';

class RealStateMap {

    constructor( module ) {

        this._APIKEY = "AIzaSyC3NgNYr_FU7WKDORrCkabA8gcBesIS0A4";
        this._DEFAULT_LAT_LNG = { lat: 41.83825582437653, lng: 2.280248441050165 }; // Tortadès headquarters.

        this._DOM_MAP = new Map();
        this._DOM_MAP.set( "module", module );
        this._DOM_MAP.set( "swiperMap", module.querySelector( ".swiper-map" ) );
        this._DOM_MAP.set( "swiperMapPagination", module.querySelector(".swiper-map .slider-navigation .swiper-pagination") );
        this._DOM_MAP.set( "swiperMapNextButton", module.querySelector(".swiper-map .slider-navigation .swiper-button-next") );
        this._DOM_MAP.set( "swiperMapPrevButton", module.querySelector(".swiper-map .slider-navigation .swiper-button-prev") );
        this._DOM_MAP.set( "parcelMapList", Array.from( module.querySelectorAll(".gbcn-real-state-box") ) );
        this._DOM_MAP.set( "googleMap", module.querySelector( ".gbcn-real-state-map__google-map" ) );
        this._DOM_MAP.set( "noResults", module.querySelector( ".gbcn-real-state-map__no-results" ) );
        this._DOM_MAP.set( "parcelGalleryList", Array.from( module.querySelectorAll(".gbcn-real-state-box .swiper ") ) );
;
        this._initSwiper();
        this._initMap();
    }

    _initSwiper() {

      if( this._DOM_MAP.get( "noResults" ) ) return;
            
      this._swiperInstance = new Swiper( this._DOM_MAP.get("swiperMap"), {

        modules: [Navigation, Pagination],
        allowTouchMove: false,
        pagination: {
          el: this._DOM_MAP.get("swiperMapPagination"),
          type: 'fraction',
        },
        navigation: {
          nextEl: this._DOM_MAP.get("swiperMapNextButton"),
          prevEl:  this._DOM_MAP.get("swiperMapPrevButton"),
        },
      });

      this._changeSlideHandler = this._changeSlideHandler.bind( this );
      this._swiperInstance.on('slideChange', this._changeSlideHandler );
    }

    _changeSlideHandler( e ) {

      if( this._currentSelectedMarker ) {

        this._currentSelectedMarker.setAnimation(null);
      }

      const currentSlidePosition =  this._swiperInstance.activeIndex;
      this._showSelectedMarker( this._markers[ currentSlidePosition ] ); 
      this._map.panTo( this._currentSelectedMarker.getPosition() );
      this._initGallerySlider( currentSlidePosition );
    }

    _initGallerySlider( id ) {

      if( this._currentParcelGallerySlider ) {

        this._currentParcelGallerySlider.destroy();
      }

      const swiper = this._DOM_MAP.get( "parcelGalleryList")[ id ];
      const swiperNextButton = swiper.querySelector(".swiper-button-next");
      const swiperPrevButton = swiper.querySelector(".swiper-button-prev");
      const swiperPagination = swiper.querySelector(".swiper-pagination");

      this._currentParcelGallerySlider = new Swiper(swiper, {

				modules: [Navigation, Pagination],
				pagination: {
					el: swiperPagination ,
					type: 'fraction',
				},
				navigation: {
					nextEl: swiperNextButton,
					prevEl: swiperPrevButton,
				},
			});
    }

    destroy() {

        if( this._swiperInstance ) { 

            this._swiperInstance.off('slideChange', this._changeSlideHandler );
            this._swiperInstance.destroy();
            this._swiperInstance = null;
        }

        if( this._currentParcelGallerySlider ) {

          this._currentParcelGallerySlider.destroy();
          this._currentParcelGallerySlider = null;
        }

        this._destroyMap();
    }
    
    /* MAP FEATURES */

    // Returns latitude and longitude of the indicated parcel.
    _getLtnLngFromParcelNum( num ) {

        const parcel = this._DOM_MAP.get("parcelMapList")[ num ];
        return { lat: parseFloat( parcel.dataset.goldLat ), lng: parseFloat( parcel.dataset.goldLng ) };
    }

    // Return title of the indicated card.
    _getTitleFromParcelNum( num ) {

      const parcel = this._DOM_MAP.get("parcelMapList")[ num ];
      const title = parcel.querySelector("a").innerText;
      const place = parcel.querySelector(".data").innerText;
      return (title + "\n" + place).trim();
  }

    // Places markers on the map based on the slides present in the slider.
    _populateMap()  {

        this._markers = [];

        this._DOM_MAP.get( "parcelMapList" ).map( ( parcel, i ) => {
   
            const marker = new google.maps.Marker({
                position: this._getLtnLngFromParcelNum( i ),
                map: this._map,
                icon: {
                    url: `${this._DOM_MAP.get("module").dataset.goldMarker}/wp-content/themes/tortades/images/google-maps-marker.png`,
                },
                title: this._getTitleFromParcelNum( i ),
                id: i
            });

            const markerClickHandler = this._markerClickHandler.bind( this, marker );
            marker.addListener( "click", markerClickHandler );
            this._markers.push( marker );
        })
    }

    // Adjust the map to display all the markers
    _fitMarkersInMap() {

      const bounds = new google.maps.LatLngBounds();

      this._markers.forEach(marker => {
          bounds.extend(marker.getPosition());
      });

      this._map.fitBounds(bounds);
    }

    // Marker Click Event Handler;
    _markerClickHandler( marker)  {
      
      this._showSelectedMarker( marker ); 
      this._swiperInstance.slideTo( marker.id );
    }

    // Show the selected marker.
    _showSelectedMarker( newMarker ) {

      if( this._currentSelectedMarker ) {

        this._currentSelectedMarker.setAnimation(null);
      }

      this._currentSelectedMarker = newMarker;
      this._currentSelectedMarker.setAnimation(google.maps.Animation.BOUNCE);
    }

    // Initialize the clusters.
    _initClusters() {

      const self = this;
      // Define the styles of the cluster icon.
      const renderer = {

        render( { count, position } ) {
    
            return new google.maps.Marker(
              {

                label: { text: String(count), color: "white", fontSize: "12px" },
                position,
                icon:  {
                  url: `${self._DOM_MAP.get("module").dataset.goldMarker}/wp-content/themes/tortades/images/market-clusterer.png`,
                  height: 32,
                  width: 32,
                  opacity: 0.3
                },
                zIndex: Number(google.maps.Marker.MAX_ZINDEX) + count,
              }
            )
        }
      }
  
      const clusterOptions = {
        markers: this._markers,
        map: this._map,
        renderer: renderer
      };

      this._markerClusterer = new MarkerClusterer( clusterOptions );
    }

    // Destroy the map instance.
    _destroyMap() {

      if(this._markers) {
        this._markers.forEach(  marker => {
        
          google.maps.event.clearInstanceListeners(marker)
          marker.setMap(null);
        });
    
        this._markers = [];
        this._markersClickHandlersList = null;
      }

      this._map = null;
    }

    // Initialize the map.
    async _initMap() {
          
      const loader = new Loader({
          apiKey: this._APIKEY,
          version: "weekly",
          language: document.documentElement.lang,
      });

      loader.load().then(

        async () => {

          const { Map } = await google.maps.importLibrary("maps");

          this._map = new google.maps.Map( 

            this._DOM_MAP.get("googleMap") , 
              {
                // center: latLng,
                zoom: 15,
                maxZoom: 18,
                disableDefaultUI: true, // a way to quickly hide all controls
                mapTypeControl: false,
                scaleControl: true,
                zoomControl: true,
                fullscreenControl: false,
                gestureHandling: "greedy",
                styles: [
                  {
                    featureType: "poi.business",
                    stylers: [
                      { visibility: "off" }
                    ]
                  },
                  {
                    featureType: "poi.medical",
                    stylers: [
                      { visibility: "off" }
                    ]
                  },
                  {
                    featureType: "poi.place_of_worship",
                    stylers: [
                      { visibility: "off" }
                    ]
                  },
                  {
                    featureType: "poi.school",
                    stylers: [
                      { visibility: "off" }
                    ]
                  },
                  {
                    featureType: "poi.sports_complex",
                    stylers: [
                      { visibility: "off" }
                    ]
                  },
                  {
                    featureType: "poi.attraction",
                    stylers: [
                      { visibility: "off" }
                    ]
                  },
                  {
                    featureType: "poi.government",
                    stylers: [
                      { visibility: "off" }
                    ]
                  }
                ]
              }
            );

            let latLng = this._DEFAULT_LAT_LNG;

            if( !this._DOM_MAP.get( "noResults" ) ) {
            
              latLng = this._getLtnLngFromParcelNum( 0 );
              this._populateMap();
              this._showSelectedMarker( this._markers[0] )
              this._initGallerySlider( 0 )
              this._initClusters();
              this._fitMarkersInMap();
            }   

            this._map.setCenter( latLng );
          }
      );
    }
}

export default RealStateMap;