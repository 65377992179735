class LanguageSelector {
	constructor() {
	
		let el = document.querySelector('#language-selector');
		if (el) {
			el.addEventListener('change', (e) => {
				location.href = e.target.value;
			})
		}
	}
}


export default LanguageSelector;
