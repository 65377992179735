import SearchFilters from "../behaviours/SearchFilters";
import FeaturedRealStateWithSlider from "../behaviours/FeaturedRealStateWithSlider";
import FeaturedRealStateWithGallery from "../behaviours/FeaturedRealStateWithGallery";
import FeaturedRealStateProject from "../behaviours/FeaturedRealStateProject";
import FeaturedBoxes from "../behaviours/FeaturedBoxes";
import RealStateMap from "../behaviours/RealStateMap";
// import BackLines from "../behaviours/BackLines";
import BannerHeader from "../behaviours/BannerHeader";

const behaviourList = [
	{ module : ".gbcn-search-filters", name: SearchFilters },
	{ module : ".gbcn-featured-boxes", name: FeaturedBoxes },
	{ module : ".gbcn-featured-real-state:not(.nogallery)", name: FeaturedRealStateWithSlider },,
	{ module : ".gbcn-featured-real-state.nogallery", name: FeaturedRealStateWithGallery },
	{ module : ".gbcn-featured-real-state-project", name: FeaturedRealStateProject },
	{ module : ".gbcn-real-state-map", name: RealStateMap },
	// { module : ".gbcn-back-lines", name: BackLines },
	{ module : ".gbcn-banner-header" , name: BannerHeader },
]

class BehaviourLauncher {

	constructor() {

		this._looksForBehaviorsAndInitializesThem();
	}

	_looksForBehaviorsAndInitializesThem() {

		this._instantiatedBehaviors = [];

		behaviourList.forEach( behaviour => 
			{
				const foundModules = Array.from( document.querySelectorAll( behaviour.module) );
				foundModules.forEach( module => {
					this._instantiatedBehaviors.push( new behaviour.name( module ) );
				})
			 }
		)
	}

	restart() {

		this._instantiatedBehaviors.forEach( behaviour => behaviour.destroy() );
		this._looksForBehaviorsAndInitializesThem();
	}
}

export default BehaviourLauncher;