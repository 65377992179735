import ShareModal from "../common/ShareModal";

class FeaturedRealStateWithGallery {

    constructor( module ) {

        this._DOM_MAP = new Map();
        this._DOM_MAP.set( "module", module );
        // this._DOM_MAP.set( "share-button", module.querySelector(".share-button")  );
        this._DOM_MAP.set( "share-modal", module.querySelector(".tts-share-modal") );

        this._initShareButton();
    }

    _initShareButton() {

        this._shareButtonHandler = this._shareButtonHandler.bind(this);
        Array.prototype.forEach.call(document.querySelectorAll('.share-button'), (e) => {
            e.addEventListener("click", this._shareButtonHandler );
        })
//        this._DOM_MAP.get("share-button").addEventListener("click", this._shareButtonHandler );
    }

    _shareButtonHandler( e) {

        e.preventDefault();
        this._shareModal = new ShareModal( this._DOM_MAP.get("share-modal"), this.shareModalCloseHandler );
    }

    shareModalCloseHandler() {

        this._shareModal = null;
    }

    destroy() {

        Array.prototype.forEach.call(document.querySelectorAll('.share-button'), (e) => {
            e.removeEventListener("click", this._shareButtonHandler );
        })

        // this._DOM_MAP.get("share-button").removeEventListener("click", this._shareButtonHandler );
        this._shareModal = null;
    }
}

export default FeaturedRealStateWithGallery;