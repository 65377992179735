class SearchFilters {

    constructor( module ) {

        this._MATCH_MEDIA = '(min-width: 660px)';
        this._matchMediaInstance = undefined;
        this._currentOpenedDropDown = undefined;

        this._DOM_MAP = new Map();
        this._DOM_MAP.set( "module", module );
        this._DOM_MAP.set( "advancedSearchFiltersContainer", module.querySelector( ".advanced-search" ) );
        this._DOM_MAP.set( "advancedSearchFiltersContainerWrapper", module.querySelector( ".advanced-search-filters-wrapper" ) );
        this._DOM_MAP.set( "closeAdvancedSearchButton", module.querySelector( ".bt-close-advanced" ) ); 
        this._DOM_MAP.set( "openAdvancedSearchButton", module.querySelector( ".bt-open-advanced" ) ); 
        this._DOM_MAP.set( "dropdownList", Array.from( module.querySelectorAll( "[name='ttsSearchRadio']" ) ) );
        this._DOM_MAP.set( "filtersMobile", module.querySelector( "#filters-mobile" ) );

        this._init();
    } 

    _init() {

        this._initCheckWidth();
        this._initFiltersUI();

        this._openCloseClickHandler = this._openCloseClickHandler.bind( this );
        this._dropdownClickHander = this._dropdownClickHander.bind( this );

        this._DOM_MAP.get("advancedSearchFiltersContainer").addEventListener("transitionend", e => {

            if( e.target !== this._DOM_MAP.get("advancedSearchFiltersContainer" ) ) return;

            this._DOM_MAP.get("advancedSearchFiltersContainer").classList.contains("visible")?  
                this._DOM_MAP.get("advancedSearchFiltersContainer").style.overflow= "visible" :
                this._DOM_MAP.get("advancedSearchFiltersContainer").style.overflow= null; 

          });

          this._DOM_MAP.get("advancedSearchFiltersContainer").addEventListener("transitionstart", e => {

            if( e.target !== this._DOM_MAP.get("advancedSearchFiltersContainer" ) ) return;

            this._DOM_MAP.get("advancedSearchFiltersContainer").classList.contains("visible")? 
                this._DOM_MAP.get("advancedSearchFiltersContainer").style.overflow= null :
                this._DOM_MAP.get("advancedSearchFiltersContainer").style.overflow= "hidden"; 
           
        });

        this._DOM_MAP.get( "openAdvancedSearchButton" ).addEventListener( "click", this._openCloseClickHandler );
        this._DOM_MAP.get( "closeAdvancedSearchButton" ).addEventListener( "click", this._openCloseClickHandler );
        this._DOM_MAP.get( "module").addEventListener( "click", this._dropdownClickHander );  
    }

    _dropdownClickHander( e ) {

        if( e.target.name !== "ttsSearchCheckbox" ) return;
        if( e.target.checked && this._currentOpenedDropDown && this._currentOpenedDropDown !== e.target) {
            this._currentOpenedDropDown.checked = false;

            // if( !matchMediaInstance.matches ) {
                this._scrolltoDropdown = this._scrolltoDropdown.bind(this);
                const listOptionsNode = e.target.parentNode.querySelector(".filter-list");
                listOptionsNode.addEventListener("transitionend", this._scrolltoDropdown );
            // }
        }
        this._currentOpenedDropDown = e.target;
    }

    _openCloseClickHandler( e ) {
        
        if( this._currentOpenedDropDown && this._currentOpenedDropDown.checked ) {
            this._currentOpenedDropDown.checked = false;
        }
    }

    _scrolltoDropdown( dropdown ) {
 
        dropdown.target.scrollIntoView({ block: "center", behavior: "smooth" }); 
        dropdown.target.removeEventListener("transitionend", this._scrolltoDropdown );
    }

    _initCheckWidth () {

        this._matchMediaInstance = window.matchMedia( (`${this._MATCH_MEDIA}`) );
        this._checkWidthHandler = this._checkWidthHandler.bind(this);
        this._matchMediaInstance.addEventListener( "change", this._checkWidthHandler );
    }
    
    _checkWidthHandler( mm ) {

        this._checkWidth();
    }

    _checkWidth() {

        if( this._matchMediaInstance.matches && this._DOM_MAP.get("filtersMobile").classList.contains("opened") ) {
            this._DOM_MAP.get("filtersMobile").classList.remove("opened");
        }
    }

    destroy() {

        this._matchMediaInstance.removeEventListener( "change", this._checkWidthHandler );
    }

    /* -------------------------------- */

    _initFiltersUI() {

        // Tab1
		let el = document.querySelector('.gbcn-search-filters #tab1');
		if (el) {
			el.addEventListener('click', (e) => {
                document.querySelector('[name="pestana"]').value = 'venda';	
                this._actualizaResultados();
			})
		}
		// Tab 2
		el = document.querySelector('.gbcn-search-filters #tab2');
		if (el) {
			el.addEventListener('click', (e) => {
                document.querySelector('[name="pestana"]').value = 'lloguer';
                this._actualizaResultados();
			})
		}
		// Tab 3
		el = document.querySelector('.gbcn-search-filters #tab3');
		if (el) {
			el.addEventListener('click', (e) => {
                document.querySelector('[name="pestana"]').value = 'solar';
                this._actualizaResultados();
			})
		}
		// Abrir opciones busqueda mobile
		// el = document.querySelector('.gbcn-search-filters .bt-open-filter-mobile');
        el = document.querySelector('.gbcn-filters-view .bt-open-filter-mobile');
		if (el) {
			el.addEventListener('click', (e) => {
				document.getElementById('filters-mobile').classList.add('opened');
                document.documentElement.style.overflow = "hidden";
			});
		}
		// Cerrar opciones busqueda mobile
		el = document.querySelector('.gbcn-search-filters .bt-close-filter');
		if (el) {
			el.addEventListener('click', (e) => {
				document.getElementById('filters-mobile').classList.remove('opened');
                document.documentElement.style.overflow = null;
			});
		}
		// Abrir opciones busqueda Avanzadas
		el = document.querySelector('.gbcn-search-filters #button-open-advanced');
		if (el) {
            el.addEventListener('click', (e) => {
                this._openAdvancedSearch();
			});
		}
		// Cerrar opciones busqueda Avanzadas
		el = document.querySelector('.gbcn-search-filters #button-close-advanced');
		if (el) {
			el.addEventListener('click', (e) => {
                this._closeAdvancedSearch();
			});
        }
        // Borrar filtros
        /*
        this._deleteFilters(1);
        this._deleteFilters(2);
        this._deleteFilters(3);
	    */
        
        // Pestañas
        Array.prototype.forEach.call(document.querySelectorAll('.tabs-nav a'), (e) => {
            e.addEventListener('click', (elem) => {
                elem.preventDefault();
                let actual = document.querySelector('[ name="pestana" ]').value;
                let nuevo = elem.target.id;
                if (actual != nuevo) {
                    document.querySelector('[ name = "current_page" ]').value = 1;
                    document.querySelector('#' + actual).classList.remove('active');
                    document.querySelector('#' + nuevo).classList.add('active');
                    document.querySelector('[ name="pestana" ]').value = nuevo;
                    this._actualizaResultados();
                }
            });

        })

        // Submits
        Array.prototype.forEach.call(document.querySelectorAll('button[type="submit"]'), (e) => {
            e.addEventListener('click', (e) => {
                e.preventDefault();
                if ('sfb' == e.target.id) {
                    if (document.querySelector('[ name ="last_search_by_word" ]').value != document.querySelector('[name="search_by_word" ]')) {
                        document.querySelector('[ name = "current_page" ]').value = 1;
                    }                        
                }
                this._actualizaResultados();
            });
        });

        // Capture el submit del formulario de búsqueda por palabra
        el = document.querySelector('.search-form');
        if (el) {
            el.addEventListener('submit', (e) => {
                e.preventDefault();
                if (document.querySelector('[ name ="last_search_by_word" ]').value != document.querySelector('[name="search_by_word" ]')) {
                    document.querySelector('[ name = "current_page" ]').value = 1;
                    this._actualizaResultados();
                }
            });
        }

        // Filtro mapa - lista en solares
        el = document.querySelector(' #filtro_mapa_lista_section');
        if (el) {
/*
            if ('solar' == document.querySelector('[name="pestana"]').value) {
                // Si es solar, muestro estas opciones
                el.classList.add('gmdf-show-type-catalog-options');
            } else {
                el.classList.remove('gmdf-show-type-catalog-options');
            }
*/            
            if ('lista' == document.querySelector('[name="filtro_mapa_lista"]').value) {
                document.querySelector('#filtro_mapa_lista_mapa').classList.remove('gmdf-selected');
                document.querySelector('#filtro_mapa_lista_lista').classList.add('gmdf-selected');
                document.querySelector('.gbcn-grid-real-state').style.display = 'block';
                document.querySelector('.gbcn-real-state-map').style.display = 'none';
                el.classList.remove('gmdf-selected-map-type-catalog');
            } else {
                document.querySelector('#filtro_mapa_lista_mapa').classList.add('gmdf-selected');
                document.querySelector('#filtro_mapa_lista_lista').classList.remove('gmdf-selected');
                document.querySelector('.gbcn-grid-real-state').style.display = 'none';
                document.querySelector('.gbcn-real-state-map').style.display = 'block';
                el.classList.add('gmdf-selected-map-type-catalog');
            }
            document.querySelector('#filtro_mapa_lista_mapa').addEventListener('click', (e) => {
                e.preventDefault();
                e = this._findElement(e.target,'A');
                // console.log(e);
                if ('lista' == document.querySelector('[name="filtro_mapa_lista"]').value ) {
                    document.querySelector('[name="filtro_mapa_lista"]').value = 'mapa';
                    e.classList.add('gmdf-selected');
                    document.querySelector('#filtro_mapa_lista_lista').classList.remove('gmdf-selected');
                    document.querySelector('#filtro_mapa_lista_section').classList.add('gmdf-selected-map-type-catalog');
                    document.querySelector('.gbcn-grid-real-state').style.display = 'none';
                    document.querySelector('.gbcn-real-state-map').style.display = 'block';
                }
            })
            document.querySelector('#filtro_mapa_lista_lista').addEventListener('click', (e) => {
                e.preventDefault();
                e = this._findElement(e.target,'A');
                // console.log(e);
                if ('mapa' == document.querySelector('[name="filtro_mapa_lista"]').value) {
                    document.querySelector('[name="filtro_mapa_lista"]').value = 'lista';
                    e.classList.add('gmdf-selected');
                    document.querySelector('#filtro_mapa_lista_mapa').classList.remove('gmdf-selected');
                    document.querySelector('#filtro_mapa_lista_section').classList.remove('gmdf-selected-map-type-catalog');
                    document.querySelector('.gbcn-grid-real-state').style.display = 'block';
                    document.querySelector('.gbcn-real-state-map').style.display = 'none';    
                    
                }
            })            
        }

        // Order by
        document.querySelector('#list_order').addEventListener('change', (e) => {
            document.querySelector('#order_by').value = e.target.value;
            this._actualizaResultados();
        });

		// Filtros
		let tmp = false;
		let total = 0;
        Array.from( document.querySelectorAll('.gbcn-search-filters .filter-list') ).forEach( e => {
			total = 0;
            Array.from( e.querySelectorAll('input[type="checkbox"]') ).forEach( c => {
				if (c.checked && '-1' != c.value) {
					total++;
				}
				c.addEventListener('click', (e) => {
					this._actualizaOpcinesFiltro(e);
				})
			});
			if (0 == total) {
				if (e.querySelector('[value="-1"]')) {
					e.querySelector('[value="-1"]').checked = true;
				}
			}
			this._actualizaOpcionesElegidas(e);
        });
        if (tmp = document.querySelector('#advanced_serach')) {
            if ('open' == tmp.value) {
                this._openAdvancedSearch();
            }
        }
        if ('solar' == document.querySelector('[name="pestana"]').value) {
            document.querySelector('#button-open-advanced').style.display = 'none';
            document.querySelector('#button-close-advanced').style.display = 'none';
            document.querySelector('#advanced-search-filters').style.display = 'none';
        }

        // Tipo de búsqueda (por palabras o por los filtros)
        if ('' != document.querySelector('[name="search_by_word"]').value) {
            document.querySelector('#filters-mobile').style.display = 'none';
            document.querySelector('.search-by-word').style.display = 'flex';
        }

        // Paginador
        if (document.querySelector('#fincas-view-more')) {
			document.querySelector('#fincas-view-more').addEventListener('click', (e) => {
                e.preventDefault();
				this._loadMore();
			})	
		}
    }

    _deleteFilters(id) {
        el = document.querySelector('.gbcn-search-filters #button-delete-filters-1');
        if (el) {
            el.addEventListener('click', (e) => {
                document.getElementById('advanced-search-filters').classList.remove('visible');
                document.getElementById('button-close-advanced').style.display = 'none';
                document.getElementById('button-open-advanced').style.display = 'flex';
                document.getElementById('button-delete-filters-2').style.display = 'flex';
                document.getElementById('button-delete-filters-3').style.display = 'none';
                document.querySelector('#advanced_serach').value = "close";
            });
        }
    }

    _loadMore() {
        document.querySelector('[name="current_page"]').value = document.querySelector('[name="next_page"]').value;
        this._actualizaResultados();
	}

    _openAdvancedSearch() {
        document.getElementById('advanced-search-filters').classList.add('visible');
        document.getElementById('button-close-advanced').style.display = 'flex';
        document.getElementById('button-open-advanced').style.display = 'none';
        document.getElementById('button-delete-filters-2').style.display = 'none';
        document.getElementById('button-delete-filters-3').style.display = 'flex';
        document.querySelector('#advanced_serach').value = "open";
    }

    _closeAdvancedSearch() {
        document.getElementById('advanced-search-filters').classList.remove('visible');
        document.getElementById('button-close-advanced').style.display = 'none';
        document.getElementById('button-open-advanced').style.display = 'flex';
        document.getElementById('button-delete-filters-2').style.display = 'flex';
        document.getElementById('button-delete-filters-3').style.display = 'none';
        document.querySelector('#advanced_serach').value = "close";
    }

    _actualizaOpcinesFiltro(e) {

        if (e.target) {
            e = e.target;
        }
        let parent = this._getParentByTagName(e, 'ul');
        if ( -1 == e.value && e.checked ) {
            // Tots
            Array.from( parent.querySelectorAll('input[type="checkbox"]') ).forEach( x => {
                if (-1 != x.value) {
                    x.checked = false;
                }
            })
        } else {
            // Altre
            let tots = parent.querySelector('input[value="-1"]');
            if (tots) {
                let otro = false;
                Array.from( parent.querySelectorAll('input[type="checkbox"]') ).forEach( x => {
                    if (-1 != x.value && x.checked) {
                        otro = true;
                    }
                });
                tots.checked = !otro;
            }
        }
        this._actualizaOpcionesElegidas(parent);
    }

    _actualizaOpcionesElegidas(e) {

        let parent = this._getParentByTagName(e, 'div');
        let texto = '';
        Array.from( e.querySelectorAll('input[type="checkbox"]') ).forEach( c => {
            if (c.checked) {
                if ('' != texto) {
                    texto +=', ';
                }
                texto += c.parentElement.textContent;
            }
        });
        parent.querySelector('.filter-select-text').innerHTML = texto;
    }

    _actualizaResultados() {        
        let data = new FormData();
        data.append('search_by_word', document.querySelector('[name="search_by_word"]').value);
        data.append('current_page', document.querySelector('[name="current_page"]').value);
        data.append('order_by', document.querySelector('[name="order_by"]').value);
        data.append('filtro_mapa_lista', document.querySelector('[name="filtro_mapa_lista"]').value);
        data.append('pag', document.querySelector('[name="current_page"]').value);
        Array.prototype.forEach.call(document.querySelectorAll('#filters-mobile input'), (e) => {
            if ('checkbox' == e.getAttribute('type') && e.checked) {
                data.append(e.getAttribute('name'), e.value);
            }
            if ('hidden' == e.getAttribute('type')) {
                data.append(e.getAttribute('name'), e.value);
            }
        });
        let url = window.location.pathname;
        let xmlhttp = this._ajaxReq()
    
        xmlhttp.open( 'POST', url, true ) // set true for async, false for sync request
        xmlhttp.onreadystatechange = function () {
            if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {				
                try {
                    const parser = new DOMParser();
                    let data = parser.parseFromString( xmlhttp.response, 'text/html' );
                    
                    // blog-content
                    document.querySelector('body').innerHTML = data.querySelector('body').innerHTML;                    
                    window.mainMenu.clean();
                    window.behaviourLauncher.restart();
//                    window.barba.initBarba()
                    window.modulesLoader.loadModules();
                } catch (error) {
                    // Show notification
                    // console.log(xmlhttp.statusText);
                }
            }
        }
        xmlhttp.send(data);    
    }

     /**
     * Get parent node for given tagname
     * @param  {Object} node    DOM node
     * @param  {String} tagname HTML tagName
     * @return {Object}         Parent node
     */
    _getParentByTagName(node, tagname) {

        let parent;
        if (node === null || tagname === '') return;
        parent  = node.parentNode;
        tagname = tagname.toUpperCase();

        while (parent.tagName !== "HTML") {
            if (parent.tagName === tagname) {
                return parent;
            }
            parent = parent.parentNode;
        }

        return parent;
    }

    _ajaxReq() {
        if (window.XMLHttpRequest) {
            return new XMLHttpRequest() // eslint-disable-line
        } else if (window.ActiveXObject) {
            return new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line
        } else {
            console.log('Browser does not support XMLHTTP.')
            return false
        }
    }

    _findElement(element, targetId) {
        // Verificar si el elemento actual tiene el ID que estamos buscando
        if (element.tagName === targetId) {
            return element;
        }
        
        // Si el elemento no es el que estamos buscando y no tiene padre, devolver null
        if (!element.parentElement) {
            return null;
        }
        
        // Llamar recursivamente a la función con el padre del elemento actual
        return this._findElement(element.parentElement, targetId);
    }
}

export default SearchFilters;