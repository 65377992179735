class Forms {

	constructor(eventEmitter, id, domNode, props = undefined) {
		this.init_loop();
  	}

	init_loop() {

		Array.prototype.forEach.call(document.querySelectorAll('[data-gold-form="forms"]'), (e) => {
			this.init( e );
		});
	}
  
	init( form ) {

		form.querySelector('[type="submit"]').dataset.goldFormId = form.id;
		form.querySelector('[type="submit"]').addEventListener('click', Forms.submit);
		//
		// Oculto todos los campos
		//
		Forms.showAndHide( form );
		//
		// Capturo el cambio de selector de contacto para que muestre u oculte los campos necesarios
		//
		let condicional = form.querySelectorAll('[name="_selector_de_mail"]');
		if (condicional) {
			Array.prototype.forEach.call(condicional, function (element) {
				element.addEventListener('change', function (e) { Forms.showAndHide(document.querySelector('[data-gold-form="forms"]')) });
			});
		}
		//
		// Aplico las restricciones a los campos obligatorios
		//
		let required = form.querySelectorAll('[required]');
		if (required) {
			Array.prototype.forEach.call(required, function (element) {
				Forms.addEvent(element, form, Forms.checkMandatory);
			});
		}
		//
		// Aplico las restricciones a los campos de consentimiento informado que sean obligatorios
		//
		for (let n = 0; n < form.querySelector('[name="_consentimientos_informados"]').value; n++) {
			let ch = form.querySelector('[name="consentimiento_informado_' + n + '"]');
			if (ch.getAttribute('data-gold-mandatory')) {
				Forms.addEvent(ch, form, Forms.checkMandatory);
			}
		}
		//
		// Aplico las restricciones a los campos e-mail, si es necesario
		//
		if ('1' == form.querySelector('[name="_valida_email"').value) {
			let email = form.querySelectorAll('[type="email"]');
			if (email) {
				Array.prototype.forEach.call(email, function (element) {
					Forms.addEvent(element, form, Forms.checkEmail);
				});
			}
		}
		//
		// Preparo para adjuntar fichero (si es que hay)
		//
		let upload = document.querySelector('.formulario__dropzone-label');
		if (upload) {
			upload.addEventListener('click', Forms.uploadFiles);
			document.querySelector('.formulario__dropzone-input').addEventListener('change', Forms.updateFileName);
			document.querySelector('.formulario__dropzone-icon').addEventListener('click', Forms.clearFilename);
		}
	}

	static updateFileName(e) {
		let file = document.querySelector('.formulario__dropzone-input');
		let label = document.querySelector('.formulario__dropzone-label');
		let icon = document.querySelector('.formulario__dropzone-icon');
		if (file.files.length > 0) {
			label.value = file.files[0].name;
			icon.style.display = 'grid';
		} else {
			label.value = '';
			icon.style.display = 'none';
		}
		label.readOnly = true; 
	}

	static clearFilename(e) {
		let file = document.querySelector('.formulario__dropzone-input');
		let label = document.querySelector('.formulario__dropzone-label');
		let icon = document.querySelector('.formulario__dropzone-icon');
		// borro los ficheros que hayan en el file
		file.type = 'text';
		file.type = 'file';
		label.value = '';
		icon.style.display = 'none';
		label.readOnly = true; 
	}

	static uploadFiles(e) {
		document.querySelector('.formulario__dropzone-label').focus;
		document.querySelector('.formulario__dropzone-input').click();
	}

	static addEvent(element, form, func) {
		
		element.dataset.goldFormSubmitButton = form.querySelector('[type="submit"]').id;
		element.dataset.goldFormId = form.id;
		element.addEventListener('change', func);
	}

	static genericCheckInputs(form, type) {

		form = Forms.getForm(form);
		type = (typeof type !== 'undefined') ? type : 'required';
		let fields = form.querySelectorAll('[required]');
		let enable = true;

		if ('email' != type && 'required' != type) {
			// type sólo puede ser "required" o "email", si no es de alguno de estos tipos, se vuelve required
			type = 'required';
		}

		if (fields) {
			Array.prototype.forEach.call(fields, function (element) {
				if ('none' != element.parentElement.style.display) {
					// Sólo los que están visibles
					let test = ('' == element.value);
					let _id = 'mandatory';
					if ('email' == type) {
						test = !Forms.validateEmail(element.value);
						_id = 'email';
					}
					if (test) {
						enable = false;
						// Sólo pongo el mensaje de error si el campo es el mismo que acaba
						// de abandonar
						if ('email' == type) {
							Forms.showErrorMsgEmail(element);
						} else {
							Forms.showErrorMsgMandatory(element);
						}
					} else {
						Forms.removeErrorMsg(element, _id);
					}
				}
			});
		}

		for (let n = 0; n < form.querySelector('[name="_consentimientos_informados"]').value; n++) {
			let ch = form.querySelector('[name="consentimiento_informado_' + n + '"]');
			if ( ch.getAttribute('data-gold-mandatory') ) {
				if (!ch.checked) {
					enable = false;
					Forms.showErrorMsgPrivacyPolicy(ch);
				} else {
					Forms.removeErrorMsg(ch, 'msg');
				}
			}
		}

//		form.querySelector('[type="submit"]').disabled = !enable;
		return enable;
	}

	static showAndHide(form) {

//		let form = this._getDomMapNode('FORM');
		if (!form.querySelector('[name="_selector_de_mail"]')) {
			return;
		}
		let value = form.querySelector('[name="_selector_de_mail"]').value;
		let mostraren = form.querySelectorAll('[data-gold-mostrar-en]');
		if (mostraren) {
			if ('0' != value) {
				Array.prototype.forEach.call(mostraren, function (element) {
					let tmp = element.dataset.goldMostrarEn.split(',');
					if ('0' == element.dataset.goldMostrarEn || '' == element.dataset.goldMostrarEn || tmp.includes(value)) {
						element.style.display = 'block';
					} else {
						element.style.display = 'none';
					}
				});
				Array.prototype.forEach.call(form.querySelectorAll('.formulario__consentimiento'), function (e) {
					e.style.display = '';
				});
				form.querySelector('.formulario__submit-button').style.display = '';
			} else {
				Array.prototype.forEach.call(mostraren, function (element) {
					if ('0' != element.dataset.goldMostrarEn || '0' == value) {
						element.style.display = 'none';
					}
				});
				Array.prototype.forEach.call(form.querySelectorAll('.formulario__consentimiento'), function (e) {
					e.style.display = 'none';
				});
				form.querySelector('.formulario__submit-button').style.display = 'none';
			}
		}

//		Forms.checkMandatory(form);
	}

	static getForm(element, tag) {

		tag = (typeof tag !== 'undefined') ? tag : 'FORM';

		if (element.target) {
			element = element.target
		}
		let tmp = element;
		for (let n = 0; n < 20; n++){
			if (null === tmp) {
				break
			} else {
				if (tag == tmp.tagName) {
					break;
				}
				tmp = tmp.parentElement;
			}
		}
		if (tmp != element) {
			element = tmp;
		}
		return element;
	}

	static checkMandatory(form) {		

		Forms.genericCheckInputs(form, 'required')
	}

	static checkEmail(form) {

		Forms.genericCheckInputs(form, 'email')
	}
	
	static genericShowMessage(element, id, msg, privacy) {
		
		privacy = (typeof privacy !== 'undefined') ? privacy : false;

		if (typeof element.dataset[msg] === 'undefined') {
			return;
		}
		Forms.addErrorClass(element);
		if (!document.querySelector('#msg-'+element.id)) {
			let newDiv = document.createElement("div");
			newDiv.id = 'msg-' + element.id;
			newDiv.classList.add('formulario__error-box');
			if (privacy) {
				element.parentNode.parentNode.insertBefore(newDiv, element.parentNode.nextSibling);
			} else {
				element.parentNode.insertBefore(newDiv, element.nextSibling);
			}
		}
		if (document.querySelector('#' + id + '-'+element.id)) {
			let el = document.querySelector('#' + id + '-'+element.id);
			el.remove();
		}
		let msgDiv = document.querySelector('#msg-' + element.id);
		let newMsg = document.createElement("div");
		newMsg.id = id + '-' + element.id;
		newMsg.classList.add('formulario__error-msg');
		newMsg.innerHTML = element.dataset[msg];
		msgDiv.appendChild(newMsg);
	}

	static removeErrorMsg(element, id) {

		element.classList.remove('formulario__error-field');
		if (document.querySelector('#' + id + '-'+element.id)) {
			document.querySelector('#' + id + '-' + element.id).remove();
		}
	}

	static showErrorMsgPrivacyPolicy(element) {

		Forms.genericShowMessage(element, 'mandatory', 'goldFormMandatoryErrorMsg',true);
	}

	static showErrorMsgMandatory(element) {

		Forms.genericShowMessage(element, 'mandatory', 'goldFormMandatoryErrorMsg');
	}

	static showErrorMsgEmail(element) {

		Forms.genericShowMessage(element, 'email', 'goldFormEmailErrorMsg');
	}

	static addErrorClass(element) {

		element.classList.add('formulario__error-field');
	} 

	static validateEmail(email) {

		const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return re.test(String(email).toLowerCase());
	}

	static submit(e) {

		Forms.preventDefaults(e);
		let formId = document.querySelector('#' + e.target.id).dataset['goldFormId'];
		let form = document.querySelector('#' + formId);
		if (!Forms.genericCheckInputs(form)) {
			return;
		}
		let formData = new FormData();
		formData.append( 'action', 'send_data_forms' );
		for (let i = 0; i < form.elements.length; i++) {
			let e = form.elements[i];
			if ('file' == e.type) {
				formData.append(e.name, e.files[0]);	
			} else {
				formData.append(e.name, e.value);
			}
		}
		let xmlhttp = Forms.ajaxReq()
		let url = encodeURI(scriptsAjaxVariables.ajax_url) // eslint-disable-line
		xmlhttp.open( 'POST', url, true ) // set true for async, false for sync request
//		xmlhttp.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded;');

		let section = Forms.getForm(form, 'SECTION');
		section.classList.add("gmdf-sending");

		let errorDisplayed = false;
		hideServiceError();

		xmlhttp.onreadystatechange = function () {

			if (xmlhttp.readyState === 4 && xmlhttp.status === 200) {
				
				try {
					switch (form.querySelector('[name="_que_hacer_cuando_envie_el_mensaje"]').value) {

						case '0':
							section.classList.remove("gmdf-sending");
							// Poner una frase
							const errorTemplate = `<div class="wrapper">
														<div class="formulario__wrapper">
															<span class="forms-thak-you-phrase">
																${form.querySelector('[name="_frase_de_texto"]').value}
															</span>
														</div>
													</div>`;
							section.innerHTML = errorTemplate;
							break;
						
						case '1':
						  	// Redirigir a una página (thank you page)
							window.location.href = section.querySelector('[name="_link_a_la_thank_you_page"]').value;
						  	break;
					}
				} catch (error) {

					showServiceError()
				}
			} else {

				showServiceError();
			}
		}
		xmlhttp.send(formData);

		function showServiceError() {

			if( !errorDisplayed ) {

				errorDisplayed = true;
				section.classList.remove("gmdf-sending");
				const msg= "Se produjo un error al procesar el envio de su solicitud. Por favor, vuelva a intentarlo en breves instantes.";
				const formNode = section.querySelector(".formulario__formulario");
				const errorTemplate = 	`<div class="formulario__error-box gld-service-error">
											<div class="formulario__error-msg">${msg}</div>
								 		</div>`;
				formNode.insertAdjacentHTML("beforeend", errorTemplate);
			}
		}

		function hideServiceError() {

			let serviceErrorNode = section.querySelector(".gld-service-error");
			if( serviceErrorNode ) {

				const formNode = section.querySelector(".formulario__formulario");
				formNode.removeChild( serviceErrorNode );
			}
		}
	}

	static preventDefaults(e) {
		
		e.preventDefault();
		e.stopPropagation();
	}

	static ajaxReq() {
		
		if (window.XMLHttpRequest) {
			return new XMLHttpRequest() // eslint-disable-line
		} else if (window.ActiveXObject) {
			return new ActiveXObject('Microsoft.XMLHTTP') // eslint-disable-line
		} else {
			console.log('Browser does not support XMLHTTP.')
			return false
		}
	}
}
  
export default Forms
  