function viewportHeightController() {

    setVhCssVariable();
    window.addEventListener('resize', setVhCssVariable);
		window.addEventListener("orientationchange", setVhCssVariable);

    function setVhCssVariable() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}

function throttle ( callback , wait = 100 ) {

    let timer = null;

    return function ( args ) {

      if ( timer === null ) {

        timer = setTimeout( () => {

          callback.apply( this, args );
          timer = null;
        }, wait )
      }
    }
  }

export { viewportHeightController, throttle }