import fslightbox from "fslightbox";
import Swiper from "swiper";
import { Pagination, Navigation } from 'swiper/modules';

/*
    Collects the images contained in the swiper slides directly.
    To do this, it gets the resource URL from the DATA-GLD-HQ-IMAGE
    attribute or, if it does not exist, from the SRC attribute.
*/

class FeaturedRealStateWithSlider {

    constructor( module ) {

        this._swiperInstance = undefined;
        this._fslightboxInstance = undefined;

        this._DOM_MAP = new Map();
        this._DOM_MAP.set( "module", module );
        this._DOM_MAP.set( "swiper", module.querySelector( ".swiper" ) );
        this._DOM_MAP.set( "fullScreenButton", module.querySelector( ".tts-fullscreen-button" ) );
        this._DOM_MAP.set( "imageList", Array.from( module.querySelectorAll( ".swiper-slide img" ) ) );

        this._initSwiper();
        this._initFslightbox();
        this._initFullScreenButton();
    }

    _initSwiper() {

        this._swiperInstance = new Swiper( this._DOM_MAP.get("swiper"), {
            modules: [Navigation, Pagination],
            pagination: {
                el: this._DOM_MAP.get("swiper").querySelector('.swiper-pagination'),
                type: 'fraction',
            },
            navigation: {
                nextEl: this._DOM_MAP.get("swiper").querySelector('.swiper-button-next'),
                prevEl: this._DOM_MAP.get("swiper").querySelector('.swiper-button-prev'),
            },
        });
    }

    _initFullScreenButton() {

        this._fullScreenButtonHandler = this._fullScreenButtonHandler.bind( this );
        this._DOM_MAP.get( "fullScreenButton" ).addEventListener( "click", this._fullScreenButtonHandler );

    }

    _fullScreenButtonHandler( e ) {

        this._fslightboxInstance.open( this._swiperInstance.activeIndex );
    }

    _initFslightbox() {

        let sources = this._DOM_MAP.get("imageList").map( 
            source => source.dataset.gldHqImage ?? source.src
        );
        this._fslightboxInstance = new FsLightbox();
        this._fslightboxInstance.props.sources = sources;
    }

    destroy() {

        this._fslightboxInstance = null;
        this._swiperInstance.destroy();
        this._DOM_MAP.get( "fullScreenButton" ).removeEventListener( "click", this._fullScreenButtonHandler );
    }
}

export default FeaturedRealStateWithSlider;