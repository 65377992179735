import Swiper from "swiper";
import { Pagination, Navigation, Controller } from 'swiper/modules';
class FeaturedRealStateProject {

    constructor( module ) {

        this._DOM_MAP = new Map();
        this._DOM_MAP.set( "module", module );
        this._DOM_MAP.set( "swiperImages", module.querySelector( ".gbcn-featured-real-state-project__gallery .swiper" ) );
        this._DOM_MAP.set( "swiperImagesPagination", module.querySelector( ".gbcn-featured-real-state-project__gallery .swiper-pagination" ) );
        this._DOM_MAP.set( "swiperImagesButtonNext", module.querySelector( ".swiper-button-next" ) );
        this._DOM_MAP.set( "swiperImagesButtonPrev", module.querySelector( ".swiper-button-prev" ) );
        this._DOM_MAP.set( "swiperTexts", module.querySelector( ".gbcn-featured-real-state-project__info .swiper" ) );

        this._initSwiper();
    }

    _initSwiper() {

        const isFixedDescription = this._DOM_MAP.get( "module" ).classList.contains("gmdf-fixed-description");

        this._swiperImages = new Swiper( this._DOM_MAP.get("swiperImages"), {
            modules: [Navigation, Pagination, Controller],
            pagination: {
                el: this._DOM_MAP.get( "swiperImagesPagination" ),
                type: 'fraction',
            },
            navigation: {
                nextEl: this._DOM_MAP.get( "swiperImagesButtonNext" ),
                prevEl: this._DOM_MAP.get( "swiperImagesButtonPrev" ),
            },
        });

        if( !isFixedDescription ) {

            this._swiperTexts = new Swiper(  this._DOM_MAP.get( "swiperTexts" ), {  modules: [Controller], allowTouchMove: false } );
            this._swiperImages.controller.control = this._swiperTexts;
        }
    }

    destroy() {

        this._swiperImages.controller.control = null;
        this._swiperTexts.destroy();
        this._swiperImages.destroy();
    }
}

export default FeaturedRealStateProject;